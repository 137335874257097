
  /* eslint-disable */
  
  import { ref, reactive, onMounted, provide } from "vue";
  import CloudFun, { defineComponent } from "@cloudfun/core";
  import TopBar from "@/components/top-bar/Main.vue";
  import FooterNav from "@/components/footer-nav/Main.vue";
  
  import LoginModal from "@/components/modal/login/Main.vue";
  import CfTabsBar from "@/components/tabs-bar/Main.vue";
  
  export default defineComponent({
    components: {
      LoginModal,
      CfTabsBar,
      TopBar,
      FooterNav
    },
    setup() {
      return {};
    },
    methods: {},
  });
  